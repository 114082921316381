<ng-container
  *tsAwaitSpinner="
    printerLabelZebraConfigs$ | async as printerLabelZebraConfigs
  "
>
  <ts-printer-label-zebra-config-table-header-ui
    slot="header"
  ></ts-printer-label-zebra-config-table-header-ui>
  <ts-printer-label-zebra-config-table-body-ui
    [printerLabelZebraConfigs]="printerLabelZebraConfigs"
    [actionTemplate]="actionTemplate"
  ></ts-printer-label-zebra-config-table-body-ui>
</ng-container>
