import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl } from '@angular/forms';

import { map, take } from 'rxjs';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ProductStaffSelectModalComponent } from '@ts/product/feature-staff-select';
import { ProductStaffPagingService } from '@ts/product/list/data-access';
import { ModalService } from '@ts/shared/modal/util-core';
import { ToastService } from '@ts/shared/util-toast';

@Component({
  selector: 'ts-product-staff-select-by-upc-form-field',
  templateUrl: './product-staff-select-by-upc-form-field.component.html',
  styleUrls: ['./product-staff-select-by-upc-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductStaffSelectByUpcFormFieldComponent extends FieldType<FieldTypeConfig> {
  ionInputControl = new FormControl();

  constructor(
    private productStaffPagingService: ProductStaffPagingService,
    private modalService: ModalService,
    private toastService: ToastService,
  ) {
    super();
  }

  private async processModal$(modalPromise: Promise<HTMLIonModalElement>) {
    const modal = await modalPromise;
    const { data } = await modal.onDidDismiss();
    if (data?.product) {
      this.formControl.setValue(data.product);
      this.ionInputControl.setValue(data.product.upc);
    }
  }

  productStaffSearch() {
    this.processModal$(
      this.modalService.show$({
        component: ProductStaffSelectModalComponent,
        backdropDismiss: false,
        size: 'fit-content',
      }),
    );
    return false;
  }

  private searchProductStaffByUpc(upc: string) {
    this.productStaffPagingService
      .getPagingTruthy$({
        queryParamsExtra: {
          upc: upc,
        },
      })
      .pipe(
        take(1),
        map((paging) =>
          paging.entities.length > 0 ? paging.entities[0] : null,
        ),
      )
      .subscribe((product) => {
        if (product) {
          this.formControl.setValue(product);
        } else {
          this.formControl.setValue(null);
          this.toastService.danger$({
            message: `Tidak ada produk dengan upc ${upc}`,
          });
        }
      });
  }

  onUpcEntered() {
    const enteredUpc = this.ionInputControl.value;
    if (enteredUpc && this.formControl.value !== enteredUpc) {
      this.searchProductStaffByUpc(enteredUpc);
    }
  }
}
