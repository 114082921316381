import { Injectable } from '@angular/core';

import { map, Observable, of } from 'rxjs';

import { PermissionsStreamService } from '@ts/shared/permission/data-access';
import { Permission } from '@ts/shared/permission/util-core';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private permissionsStreamService: PermissionsStreamService) {}

  isHasPermissions$(permissions: readonly Permission[]): Observable<boolean> {
    if (!permissions.length) return of(true);

    return this.permissionsStreamService.truthyEntity$.pipe(
      map(
        // return true iff there does not exists permission in permissions
        // that is not included in userPermissions
        (userPermissions) =>
          !permissions.find(
            (permission) => !userPermissions.includes(permission),
          ),
      ),
    );
  }
}
