import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
  Currency,
  CURRENCY_DEFAULT,
  Money,
  MoneyFormatService,
  MoneyOperationService,
} from '@ts/shared/money/util-core';

@Component({
  selector: 'ts-money-field-ui',
  templateUrl: './money-field-ui.component.html',
  styleUrls: ['./money-field-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyFieldUiComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  currency: Currency = CURRENCY_DEFAULT;

  currencyDisplay!: string;
  moneyAsNumber: number | null = null;

  subscriptions: Subscription[] = [];

  constructor(
    private moneyFormatService: MoneyFormatService,
    private moneyOperationService: MoneyOperationService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnInit() {
    if (this.formControl.value?.amount) {
      this.moneyAsNumber = this.moneyOperationService.moneyToNumber(
        this.formControl.value,
      );
    }

    this.currencyDisplay =
      this.moneyFormatService.CURRENCY_TO_DISPLAY[this.currency];

    this.subscriptions.push(
      this.formControl.valueChanges.subscribe((money: Money) => {
        if (money) {
          const newMoneyAsNumber =
            this.moneyOperationService.moneyToNumber(money);
          if (newMoneyAsNumber !== this.moneyAsNumber) {
            this.moneyAsNumber = newMoneyAsNumber;
          }
        } else {
          this.moneyAsNumber = null;
        }
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  inputChanged(value: number | null) {
    if (value !== null) {
      const money = this.moneyOperationService.numberToMoney(
        value,
        this.currency,
      );
      this.formControl.setValue(money);
    } else {
      this.formControl.setValue(null);
    }
  }
}
