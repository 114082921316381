import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-pagination-table-ui',
  templateUrl: './pagination-table-ui.component.html',
  styleUrls: ['./pagination-table-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationTableUiComponent<T> {
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<T>>;
  @Input() headerTemplate!: TemplateRef<Record<string, T>>;
  @Input() bodyTemplate!: TemplateRef<Record<string, T>>;
  @Input() instances!: readonly T[];
}
