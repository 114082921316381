import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { pick } from 'lodash';
import { take, tap } from 'rxjs';

import { NavController } from '@ionic/angular';
import {
  FormFlexibleConfig,
  FormGenericConfig,
} from '@ts/shared/form/flexible/util-core';
import { QueryParamsService } from '@ts/shared/router/data-access-params';

@Component({
  selector: 'ts-form-flexible-navigate',
  templateUrl: './form-flexible-navigate.component.html',
  styleUrls: ['./form-flexible-navigate.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFlexibleNavigateComponent<T, U extends object>
  implements OnInit
{
  @Input() config!: FormFlexibleConfig<T, U>;
  @Input() formGenericConfig!: FormGenericConfig;
  @Input() initial?: T;
  /**
   *These query params in the url will be kept in the navigation.
   */
  @Input() queryParamKeysKeep: readonly string[] = ['q'];

  initialFiltered?: T;

  constructor(
    private navController: NavController,
    private queryParamsService: QueryParamsService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    if (this.initial) {
      this.initialFiltered = pick(
        this.initial,
        this.config.fields.map((field) => field.key as string),
      ) as T;
    }
  }

  navigate(data: U) {
    this.queryParamsService
      .getQueryParams$()
      .pipe(
        take(1),
        tap((queryParams: Record<string, string>) => {
          this.navController.navigateRoot([], {
            relativeTo: this.activatedRoute,
            queryParams: {
              ...pick(queryParams, this.queryParamKeysKeep),
              ...data,
            },
          });
        }),
      )
      .subscribe();
  }
}
