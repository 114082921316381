import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { PrinterLabelHtmlConfigSelectModalComponent } from '@ts/printer/label/html-config/action/feature-select';
import { PrinterLabelHtmlConfig } from '@ts/printer/label/shared/util-core';
import { FormFieldSelectWithModalUiComponent } from '@ts/shared/form/field/ui-select-with-modal';

@Component({
  selector: 'ts-printer-label-html-config-form-field',
  templateUrl: './printer-label-html-config-form-field.component.html',
  styleUrls: ['./printer-label-html-config-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterLabelHtmlConfigFormFieldComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  modalOutputPropertyName = 'printerLabelHtmlConfig';
  initial?: PrinterLabelHtmlConfig;

  ngOnInit() {
    this.initial = this.formControl.value;
  }

  modalShowParamsCallback: FormFieldSelectWithModalUiComponent<PrinterLabelHtmlConfig>['modalShowParamsCallback'] =
    ({ entity }) => {
      return {
        component: PrinterLabelHtmlConfigSelectModalComponent,
        componentProps: {
          initial: entity,
        },
        size: 'full',
        backdropDismiss: false,
      };
    };
}
