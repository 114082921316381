import { createAction, props } from '@ngrx/store';
import { Basket } from '@ts/basket/shared/util-core';
import { CheckoutStep } from '@ts/checkout/shared/util-core';
import { PaymentInfoCheckoutFull } from '@ts/payment/shared/util-core';
import { PromoVoucher, PromoVoucherType } from '@ts/promo/shared/util-core';
import { CheckoutShippingInfo } from '@ts/shipping/shared/util-core';

export const initCheckout = createAction(
  '[checkout.shared.data-access] Init Checkout Process',
  props<{ basket: Basket }>(),
);

export const resetCheckout = createAction(
  '[checkout.shared.data-access] Reset Checkout Process',
);

export const setShippingInfo = createAction(
  '[checkout.shared.data-access] Set ShippingInfo',
  props<{ shippingInfo: CheckoutShippingInfo | undefined }>(),
);

export const setPaymentInfo = createAction(
  '[checkout.shared.data-access] Set PaymentInfo',
  props<{ paymentInfo: PaymentInfoCheckoutFull | undefined }>(),
);

export const setCheckoutStep = createAction(
  '[checkout.shared.data-access] Set CheckoutStep',
  props<{ checkoutStep: CheckoutStep }>(),
);

export const setPromoVoucher = createAction(
  '[checkout.shared.data-access] Set PromoVoucher',
  props<{ promoVoucher: PromoVoucher }>(),
);

export const clearPromoVoucher = createAction(
  '[checkout.shared.data-access] Clear PromoVoucher',
  props<{ promoVoucherType: PromoVoucherType }>(),
);
