<ts-page-card-ui>
  <ts-staff-list-wrapper-ui [linkButtonConfigs]="linkButtonConfigs">
    <ng-container slot="title">Daftar Cashier</ng-container>
    <ng-container slot="content">
      <ts-searchbar-query-param
        [navigateParams]="['/order/offline/cashier/list']"
      ></ts-searchbar-query-param>
      <ts-searchbar-result-title-ui></ts-searchbar-result-title-ui>

      <ts-pagination [pagingMeta]="cashierPaging.meta">
        <ts-cashier-table-ui
          [config]="{
            type: 'basic',
            instances: cashierPaging.entities
          }"
          [actionTemplate]="actionTemplate"
        >
          <ng-template let-value="value" #actionTemplate>
            <ts-cashier-actions [cashier]="value"></ts-cashier-actions>
          </ng-template>
        </ts-cashier-table-ui>
      </ts-pagination>
    </ng-container>
  </ts-staff-list-wrapper-ui>
</ts-page-card-ui>
