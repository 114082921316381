<tr *ngFor="let printerLabelZebraConfig of printerLabelZebraConfigs">
  <td>
    {{ printerLabelZebraConfig.name }}
  </td>
  <td>
    {{ printerLabelZebraConfig.printer_dpi }}
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: printerLabelZebraConfig }"
    ></ng-template>
  </td>
</tr>
