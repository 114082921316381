<ts-popover-button-content-ui>
  <ion-list>
    <ion-item
      [button]="true"
      [detail]="true"
      [tsRouterLink]="['/order/offline/cashier/detail', cashier.id]"
      routerDirection="forward"
      *tsIfPermission="'order_offline.view_cashier'"
    >
      Detail
    </ion-item>
    <ion-item
      [button]="true"
      [detail]="false"
      [tsRouterLink]="['/order/offline/cashier/update', cashier.id]"
      routerDirection="forward"
      *tsIfPermission="'order_offline.change_cashier'"
    >
      Perbaharui
    </ion-item>
    <ion-item
      [button]="true"
      [detail]="false"
      (click)="navigateToCashierRevision()"
      *tsIfPermission="'order_offline.view_cashier'"
    >
      Sejarah
    </ion-item>
  </ion-list>
</ts-popover-button-content-ui>
