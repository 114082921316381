<ts-modal-ui title="Pilih Kasir">
  <ts-page-card-ui>
    <ts-choices-action-template-ui
      [initial]="initial"
      (selected)="cashierSelected($event)"
    >
      <ng-template #choicesTemplate let-actionTemplate="actionTemplate">
        <ts-cashier-table-ui
          [config]="tableConfig"
          [actionTemplate]="actionTemplate"
        ></ts-cashier-table-ui>
      </ng-template>
    </ts-choices-action-template-ui>
  </ts-page-card-ui>
</ts-modal-ui>
