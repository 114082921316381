import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { Observable } from 'rxjs';

import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import {
  FormFlexibleConfig,
  FormGenericConfig,
} from '@ts/shared/form/flexible/util-core';
import { ApiEntityPagingAbstractService } from '@ts/shared/pagination/data-access-entity';
import { Paging } from '@ts/shared/pagination/util-core';

interface PaginationTableFilterConfig<U, V extends Object> {
  formFlexibleConfig: FormFlexibleConfig<U, V>;
  formGenericConfig: FormGenericConfig;
}

interface PaginationTableSearchConfig {
  navigateParams: string[];
}

export type PaginationTableType = 'paging' | 'infinite';

// searchConfig ignored for type 'infinite'
interface PaginationTableConfigBase<T, U, V extends Object> {
  type: PaginationTableType;
  pagingService: ApiEntityPagingAbstractService<T>;
  filterConfig?: PaginationTableFilterConfig<U, V>;
  searchConfig?: PaginationTableSearchConfig;
}

export type PaginationTableConfig<
  T,
  U = unknown,
  V extends Object = {},
> = PaginationTableConfigBase<T, U, V>;

@Component({
  selector: 'ts-pagination-table',
  templateUrl: './pagination-table.component.html',
  styleUrls: ['./pagination-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationTableComponent<T, U = unknown, V extends Object = {}>
  implements OnInit
{
  @Input() config!: PaginationTableConfig<T, U, V>;
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<T>>;

  paging$!: Observable<Paging<T> | null>;

  @ContentChild('headerTemplate') headerTemplate!: TemplateRef<
    Record<string, T>
  >;
  @ContentChild('bodyTemplate') bodyTemplate!: TemplateRef<Record<string, T>>;

  ngOnInit() {
    if (this.config.type === 'paging') {
      this.paging$ = this.config.pagingService.getPaging$();
    }
  }
}
