<ts-pagination-table [config]="config" [actionTemplate]="actionTemplate">
  <ng-template #headerTemplate>
    <th>Nama</th>
    <th>Fasilitas</th>
    <th>Tipe</th>
    <th>Printer Detail</th>
  </ng-template>

  <ng-template let-printer="value" #bodyTemplate>
    <td>
      <div>{{ printer.name }}</div>
      <ts-guid-ui [guid]="printer.guid"></ts-guid-ui>
      <ts-date-created-updated-ui
        [instance]="printer"
      ></ts-date-created-updated-ui>
    </td>
    <td>
      <ts-facility-mention-ui
        [facility]="printer.facility"
      ></ts-facility-mention-ui>
    </td>
    <td>
      {{ printer.type }}
    </td>
    <td>
      <ts-printer-connection-mention-ui
        [printer]="printer"
      ></ts-printer-connection-mention-ui>
    </td>
  </ng-template>
</ts-pagination-table>
