<ion-buttons>
  <ng-container *transloco="let t; scope: 'sharedPagination'">
    <ion-button
      fill="solid"
      color="medium"
      expand="block"
      (click)="pagingMove(currentPage - 1)"
      [disabled]="isPreviousButtonDisabled"
    >
      <ion-icon slot="start" name="chevron-back-outline"></ion-icon>
      {{ t('sharedPagination.previous') }}
    </ion-button>
    <ion-button
      fill="solid"
      color="medium"
      expand="block"
      (click)="pagingMove(currentPage + 1)"
      [disabled]="isNextButtonDisabled"
    >
      <ion-icon slot="end" name="chevron-forward-outline"></ion-icon>
      {{ t('sharedPagination.next') }}
    </ion-button>
  </ng-container>
</ion-buttons>
