import {
  ChangeDetectorRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { take } from 'rxjs';

import { Permission } from '@ts/shared/permission/util-core';

import { PermissionService } from '../permission.service';

@Directive({
  selector: '[tsIfPermission]',
})
export class IfPermissionDirective {
  @Input()
  set tsIfPermission(permission: Permission | Permission[]) {
    // We don't want to show a loading spinner here, because we don't want
    // users without permission to be aware that these options exists.

    this.permissionService
      .isHasPermissions$(Array.isArray(permission) ? permission : [permission])
      .pipe(take(1))
      .subscribe((isHasPermission) => {
        this.viewContainerRef.clear();
        if (isHasPermission) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else {
          // Don't show anything
        }
        // we need to detect changes in this directive because this is asynchronous.
        this.changeDetectorRef.detectChanges();
      });
  }

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private permissionService: PermissionService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}
}
