import { Injectable } from '@angular/core';

import { EMPTY, Observable, of, switchMap, take } from 'rxjs';

import { Facility } from '@ts/facility/shared/util-core';
import { PrinterLabelEscPosConfig } from '@ts/printer/label/shared/util-core';
import { AlertService } from '@ts/shared/alert/util-core';

import { PrinterLabelEscPosConfigsStreamService } from './printer-label-esc-pos-configs-stream.service';

/**
 * Wrapper for PrinterLabelEscPosConfigsStreamService to grab a config for a particular facility,
 * or throw an error if it does not exist yet.
 */
@Injectable({
  providedIn: 'root',
})
export class PrinterLabelEscPosConfigService {
  constructor(
    private printerLabelEscPosConfigsStreamService: PrinterLabelEscPosConfigsStreamService,
    private alertService: AlertService,
  ) {}

  get$(facility: Facility): Observable<PrinterLabelEscPosConfig> {
    return this.printerLabelEscPosConfigsStreamService.truthyEntity$.pipe(
      take(1),
      switchMap((configs) => {
        const config = configs.find(
          (config) => config.facility.id === facility.id,
        );
        if (config) {
          return of(config);
        } else {
          // Config not found. Show user an error and stop the observable.
          this.alertService.error$({
            message:
              `Konfigurasi printer Esc Pos untuk fasilitas ${facility.name} tidak ditemukan. ` +
              'Harap buat terlebih dahulu.',
          });

          return EMPTY;
        }
      }),
    );
  }
}
