import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PrinterLabelZebraConfigMentionUiComponent } from './printer-label-zebra-config-mention-ui/printer-label-zebra-config-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [PrinterLabelZebraConfigMentionUiComponent],
  exports: [PrinterLabelZebraConfigMentionUiComponent],
})
export class PrinterLabelZebraConfigUiMentionModule {}
