import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';

import { Cashier } from '@ts/order/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import { TableGenericConfig } from '@ts/shared/table/feature-generic';

@Component({
  selector: 'ts-cashier-table-ui',
  templateUrl: './cashier-table-ui.component.html',
  styleUrls: ['./cashier-table-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierTableUiComponent {
  @Input() config!: TableGenericConfig<Cashier>;
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<Cashier>>;
}
