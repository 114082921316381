<ng-container *transloco="let t; scope: 'sharedPopover'">
  <ion-button (click)="popoverShow($event)">
    {{ t('sharedPopover.action') }}
    <ion-icon name="caret-down" slot="end"></ion-icon>
  </ion-button>
  <ion-popover #popover [dismissOnSelect]="true">
    <ng-template #popoverContent>
      <ion-content>
        <ng-content></ng-content>
      </ion-content>
    </ng-template>
  </ion-popover>
</ng-container>
