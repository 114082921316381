import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PrinterLabelZebraConfig } from '@ts/printer/label/shared/util-core';

@Component({
  selector: 'ts-printer-label-zebra-config-mention-ui',
  templateUrl: './printer-label-zebra-config-mention-ui.component.html',
  styleUrls: ['./printer-label-zebra-config-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterLabelZebraConfigMentionUiComponent {
  @Input() printerLabelZebraConfig!: PrinterLabelZebraConfig;
}
