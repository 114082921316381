import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import {
  PaginationUtilService,
  PagingMeta,
} from '@ts/shared/pagination/util-core';

/**
 * Shows the pagination control buttons for a page.
 *
 * @example
 * ```
 * <ts-pagination-control-ui [pagingMeta]=pagingMeta (pagingPageChanged)=moveToPagingPage($event)>
 * </ts-pagination-control-ui>
 * ```
 */
@Component({
  selector: 'ts-pagination-control-ui',
  templateUrl: './pagination-control-ui.component.html',
  styleUrls: ['./pagination-control-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationControlUiComponent implements OnInit {
  @Input() pagingMeta!: PagingMeta;
  @Output() pagingPageChanged = new EventEmitter<number>();

  currentPage!: number;
  finalPage!: number;
  isPreviousButtonDisabled = false;
  isNextButtonDisabled = false;

  constructor(private paginationUtilService: PaginationUtilService) {}

  ngOnInit() {
    this.currentPage = this.pagingMeta.page;
    this.finalPage = this.paginationUtilService.computeFinalPagingPage(
      this.pagingMeta,
    );
    if (this.currentPage === 1) {
      this.isPreviousButtonDisabled = true;
    }
    if (this.currentPage >= this.finalPage) {
      this.isNextButtonDisabled = true;
    }
  }

  pagingMove(paging: number | undefined) {
    // zero should not be emitted either, so this is fine:
    if (paging) {
      this.pagingPageChanged.emit(paging);
    }
  }
}
