import { ChangeDetectionStrategy, Component } from '@angular/core';

import { strict as assert } from 'assert';
import { Observable } from 'rxjs';

import {
  FieldTypeConfig,
  FieldWrapper,
  FormlyFieldProps,
} from '@ngx-formly/core';

interface ButtonFormWrapperConfig {
  text$: Observable<string>;
  callback: () => undefined;
}

export interface ButtonFormWrapperProps extends FormlyFieldProps {
  buttonFormWrapperConfig?: ButtonFormWrapperConfig;
}

@Component({
  selector: 'ts-button-form-wrapper-ui',
  templateUrl: './button-form-wrapper-ui.component.html',
  styleUrls: ['./button-form-wrapper-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonFormWrapperUiComponent extends FieldWrapper<
  FieldTypeConfig<ButtonFormWrapperProps>
> {
  buttonClicked() {
    assert(this.props.buttonFormWrapperConfig);
    this.props.buttonFormWrapperConfig.callback();
  }
}
