import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { IfPermissionDirective } from './if-permission/if-permission.directive';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [IfPermissionDirective],
  exports: [IfPermissionDirective],
})
export class SharedPermissionFeatureCoreModule {}
