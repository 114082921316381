import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { PrinterLabelHtmlConfig } from '@ts/printer/label/shared/util-core';
import { BormaDagoPersistentAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityStreamPersistentAbstractService } from '@ts/shared/data/data-access-api-entity-stream';

/**
 * Retrieves all html configs.
 *
 * Persistent.
 */
@Injectable({
  providedIn: 'root',
})
export class PrinterLabelHtmlConfigsStreamService extends ApiEntityStreamPersistentAbstractService<
  readonly PrinterLabelHtmlConfig[]
> {
  override relativeUrl$ = of('api/printer/label/staff/html-configuration/all/');

  constructor(
    protected override apiService: BormaDagoPersistentAuthenticatedApiConsumerService,
  ) {
    super();
  }
}
