<ts-pagination-infinite-table
  *ngIf="config.type === 'infinite'"
  [pagingService]="config.pagingService"
  [filterConfig]="config.filterConfig"
>
  <tr slot="header">
    <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
    <th *ngIf="actionTemplate">Aksi</th>
  </tr>

  <ng-template let-paging="paging" #pagingTemplate>
    <tr *ngFor="let entity of paging.entities">
      <ng-template
        [ngTemplateOutlet]="bodyTemplate"
        [ngTemplateOutletContext]="{ value: entity }"
      >
      </ng-template>
      <td *ngIf="actionTemplate">
        <ng-template
          [ngTemplateOutlet]="actionTemplate"
          [ngTemplateOutletContext]="{ value: entity }"
        ></ng-template>
      </td>
    </tr>
  </ng-template>
</ts-pagination-infinite-table>

<ng-container *ngIf="config.type === 'paging'">
  <ng-container *ngIf="config.searchConfig">
    <ts-searchbar-query-param
      [navigateParams]="config.searchConfig.navigateParams"
    ></ts-searchbar-query-param>
    <ts-searchbar-result-title-ui></ts-searchbar-result-title-ui>
  </ng-container>

  <div *ngIf="config.filterConfig" class="filter">
    <ts-form-flexible-navigate
      [config]="config.filterConfig.formFlexibleConfig"
      [formGenericConfig]="config.filterConfig.formGenericConfig"
    >
    </ts-form-flexible-navigate>
  </div>

  <ng-container *tsAwaitSpinner="paging$ | async as paging">
    <ts-pagination [pagingMeta]="paging.meta">
      <ts-pagination-table-ui
        [headerTemplate]="headerTemplate"
        [bodyTemplate]="bodyTemplate"
        [actionTemplate]="actionTemplate"
        [instances]="paging.entities"
      >
      </ts-pagination-table-ui>
    </ts-pagination>
  </ng-container>
</ng-container>
