<ts-table-generic [config]="config" [actionTemplate]="actionTemplate">
  <ng-template #tableHeaderTemplate>
    <th>Nama</th>
    <th>Zona Fasilitas</th>
    <th>Tempat Penyimpanan Uang</th>
    <th>Printer</th>
  </ng-template>

  <ng-template let-cashier="value" #tableBodyTemplate>
    <td>
      <div>
        {{ cashier.name }}
      </div>
      <ts-guid-ui [guid]="cashier.guid"></ts-guid-ui>
      <ts-date-created-updated-ui
        [instance]="cashier"
      ></ts-date-created-updated-ui>
    </td>
    <td>
      <ts-facility-zone-mention-ui
        [facilityZone]="cashier.facility_zone"
      ></ts-facility-zone-mention-ui>
    </td>
    <td>
      <ts-finance-cash-storage-mention-ui
        [cashStorage]="cashier.cash_storage"
      ></ts-finance-cash-storage-mention-ui>
    </td>
    <td>
      <ts-printer-mention-ui
        [printer]="cashier.printer"
      ></ts-printer-mention-ui>
    </td>
  </ng-template>
</ts-table-generic>
