import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { BormaDagoPersistentAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityStreamPersistentAbstractService } from '@ts/shared/data/data-access-api-entity-stream';
import { Permission } from '@ts/shared/permission/util-core';

/**
 * Retrieves all permissions for the currently logged-in user.
 *
 * Persistent.
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionsStreamService extends ApiEntityStreamPersistentAbstractService<
  readonly Permission[]
> {
  override relativeUrl$ = of('api/auth/permission/all/');

  constructor(
    protected override apiService: BormaDagoPersistentAuthenticatedApiConsumerService,
  ) {
    super();
  }
}
