import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { GuidMetaService } from '@ts/shared/guid/util-core';

@Component({
  selector: 'ts-guid-link',
  templateUrl: './guid-link.component.html',
  styleUrls: ['./guid-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuidLinkComponent implements OnInit {
  @Input() guid!: string;

  url!: string;
  routerLinkOptions!: NavigationOptions;

  // This is the default fallback url for non guid: product url.
  PRODUCT_URL = '/product/list';

  constructor(private guidMetaService: GuidMetaService) {}

  ngOnInit() {
    try {
      const guidMeta = this.guidMetaService.getGuidMeta(this.guid);
      this.url = guidMeta.url;
      this.routerLinkOptions = {
        queryParams: {
          q: this.guid,
        },
      };
    } catch (error) {
      if (error instanceof TypeError) {
        this.url = this.PRODUCT_URL;
        this.routerLinkOptions = {
          queryParams: {
            upc: this.guid,
          },
        };
      } else {
        throw error;
      }
    }
  }
}
