<table>
  <thead>
    <tr>
      <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
      <th *ngIf="actionTemplate">Aksi</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngFor="let instance of instances">
      <ng-template
        [ngTemplateOutlet]="bodyTemplate"
        [ngTemplateOutletContext]="{ value: instance }"
      >
      </ng-template>
      <td *ngIf="actionTemplate">
        <ng-template
          [ngTemplateOutlet]="actionTemplate"
          [ngTemplateOutletContext]="{ value: instance }"
        ></ng-template>
      </td>
    </tr>
  </tbody>
</table>
