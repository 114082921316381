import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { PrinterPagingService } from '@ts/printer/list/data-access';
import { Printer } from '@ts/printer/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';
import {
  PaginationTableConfig,
  PaginationTableType,
} from '@ts/shared/pagination/feature-table';

@Component({
  selector: 'ts-printer-table',
  templateUrl: './printer-table.component.html',
  styleUrls: ['./printer-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterTableComponent implements OnInit {
  @Input() type!: PaginationTableType;
  @Input() actionTemplate?: TemplateRef<ActionTemplateContext<Printer>>;

  config!: PaginationTableConfig<Printer>;

  constructor(private printerPagingService: PrinterPagingService) {}

  ngOnInit() {
    this.config = {
      type: this.type,
      pagingService: this.printerPagingService,
      filterConfig: {
        formFlexibleConfig: {
          fields: [
            {
              key: 'name',
              type: 'string',
              props: {
                label: 'Nama',
              },
            },
          ],
        },
        formGenericConfig: {
          type: 'button',
          title: 'Printer Filter',
          buttonText: 'Filter',
          ignoreScreenSize: true,
        },
      },
      searchConfig: {
        navigateParams: ['/printer/list'],
      },
    };
  }
}
