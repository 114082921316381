import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PagingMeta } from '@ts/shared/pagination/util-core';

@Component({
  selector: 'ts-pagination-header',
  templateUrl: './pagination-header-ui.component.html',
  styleUrls: ['./pagination-header-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationHeaderUiComponent {
  @Input() pagingMeta!: PagingMeta;
}
