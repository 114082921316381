import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CashierMentionUiComponent } from './cashier-mention-ui/cashier-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [CashierMentionUiComponent],
  exports: [CashierMentionUiComponent],
})
export class OrderOfflineCashierUiMentionModule {}
