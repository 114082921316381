import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { PrinterLabelZebraConfigUiMentionModule } from '@ts/printer/label/zebra-config/ui-mention';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { PrinterLabelZebraConfigFormFieldComponent } from './printer-label-zebra-config-form-field/printer-label-zebra-config-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'printer-label-zebra-config',
          component: PrinterLabelZebraConfigFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    PrinterLabelZebraConfigUiMentionModule,
  ],
  declarations: [PrinterLabelZebraConfigFormFieldComponent],
  exports: [PrinterLabelZebraConfigFormFieldComponent],
})
export class PrinterLabelZebraConfigFormFeatureFieldModule {}
