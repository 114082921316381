<tr *ngFor="let printerLabelHtmlConfig of printerLabelHtmlConfigs">
  <td>
    {{ printerLabelHtmlConfig.name }}
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: printerLabelHtmlConfig }"
    ></ng-template>
  </td>
</tr>
