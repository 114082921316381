import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { PagingMeta } from '@ts/shared/pagination/util-core';

/**
 * Encapsulates pagination.
 *
 * @example
 *
 * ```
 * <ts-pagination-ui [pagingMeta]="pagingMeta" (pagingPageChanged)="pagingPageChanged($event)">
 *   <div *ngFor=...>
 *     Item xxx
 *   </div>
 * </ts-pagination-ui>
 * ```
 */
@Component({
  selector: 'ts-pagination-ui',
  templateUrl: './pagination-ui.component.html',
  styleUrls: ['./pagination-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationUiComponent {
  @Input() pagingMeta!: PagingMeta;
  @Output() pagingPageChanged = new EventEmitter<number>();

  moveToPagingPage(page: number) {
    this.pagingPageChanged.emit(page);
  }
}
