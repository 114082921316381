import {
  Directive,
  HostBinding,
  HostListener,
  Input,
  OnInit,
} from '@angular/core';

import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { LocationUtilService } from '@ts/shared/router/util-location';

@Directive({
  selector: '[tsRouterLink]',
})
export class RouterLinkDirective implements OnInit {
  // we use any since routerLink also use any, nothing we can really do
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() tsRouterLink!: [string, ...any];
  @Input() routerLinkOptions?: NavigationOptions;
  @Input() routerDirection: Parameters<NavController['setDirection']>[0] =
    'root';

  @HostBinding('attr.href') href!: string;

  constructor(
    private navController: NavController,
    private locationUtilService: LocationUtilService,
  ) {}

  @HostListener('click') clicked(): boolean {
    switch (this.routerDirection) {
      case 'back': {
        this.navController.navigateBack(
          this.tsRouterLink,
          this.routerLinkOptions,
        );
        return false;
      }
      case 'forward': {
        this.navController.navigateForward(
          this.tsRouterLink,
          this.routerLinkOptions,
        );
        return false;
      }
      case 'root': {
        this.navController.navigateRoot(
          this.tsRouterLink,
          this.routerLinkOptions,
        );
        return false;
      }
    }
  }

  ngOnInit() {
    this.href = this.locationUtilService.getExternalUrl(this.tsRouterLink);
  }
}
