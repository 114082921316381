@if (props.buttonFormWrapperConfig) {
  <div class="container">
    <div class="input-container">
      <ng-template #fieldComponent></ng-template>
    </div>
    <ion-button (click)="buttonClicked()">{{
      props.buttonFormWrapperConfig.text$ | async
    }}</ion-button>
  </div>
} @else {
  <ng-template #fieldComponent></ng-template>
}
