<ng-container
  *tsAwaitSpinner="printerLabelHtmlConfigs$ | async as printerLabelHtmlConfigs"
>
  <ts-printer-label-html-config-table-header-ui
    slot="header"
  ></ts-printer-label-html-config-table-header-ui>
  <ts-printer-label-html-config-table-body-ui
    [printerLabelHtmlConfigs]="printerLabelHtmlConfigs"
    [actionTemplate]="actionTemplate"
  ></ts-printer-label-html-config-table-body-ui>
</ng-container>
