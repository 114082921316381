import { Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';
import { sprintf } from 'sprintf-js';

import { BasketIdStreamService } from '@ts/basket/shared/data-access';
import { Basket } from '@ts/basket/shared/util-core';
import { BormaDagoPersistentAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityStreamAbstractService } from '@ts/shared/data/data-access-api-entity-stream';

/**
 * For staff pages, we don't want to make basket persistent.
 */
@Injectable()
export class BasketNonPersistentStreamService extends ApiEntityStreamAbstractService<Basket> {
  relativeUrl = 'api/basket/%d/get/';

  constructor(
    protected override apiService: BormaDagoPersistentAuthenticatedApiConsumerService,
    private basketIdStreamService: BasketIdStreamService,
  ) {
    super();
  }

  override get relativeUrl$(): Observable<string> {
    return this.basketIdStreamService.truthyEntity$.pipe(
      map((basket) => sprintf(this.relativeUrl, basket.id)),
    );
  }
}
