<ion-button
  [tsRouterLink]="[linkButtonConfig.link]"
  *tsIfPermission="[linkButtonConfig.permission]"
  [color]="linkButtonConfig.color"
>
  <ion-icon
    *ngIf="linkButtonConfig.ionIconName"
    [name]="linkButtonConfig.ionIconName"
    slot="start"
  ></ion-icon>
  {{ buttonDisplayedText | async }}
</ion-button>
