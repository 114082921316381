import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NavController } from '@ionic/angular';
import { Cashier } from '@ts/order/shared/util-core';
import { REVISION_FILTER_OBJECT_ID_QUERY_PARAM_KEY } from '@ts/shared/history/util-core';

@Component({
  selector: 'ts-cashier-actions',
  templateUrl: './cashier-actions.component.html',
  styleUrls: ['./cashier-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierActionsComponent {
  @Input() cashier!: Cashier;
  queryParamKey = REVISION_FILTER_OBJECT_ID_QUERY_PARAM_KEY;

  constructor(private navController: NavController) {}

  navigateToCashierRevision() {
    this.navController.navigateForward(['/order/offline/cashier/revision'], {
      queryParams: {
        [this.queryParamKey]: this.cashier.id,
      },
    });
  }
}
