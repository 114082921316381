import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { MaskitoElementPredicate, maskitoTransform } from '@maskito/core';
import {
  maskitoNumberOptionsGenerator,
  maskitoParseNumber,
} from '@maskito/kit';

@Component({
  selector: 'ts-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberInputComponent implements OnChanges {
  @Input() value?: number | null;
  @Output() valueChange = new EventEmitter<number | null>();

  unmaskedValue: number | null = null;
  formControl = new FormControl<string>('');

  options = maskitoNumberOptionsGenerator({
    decimalZeroPadding: true,
    precision: 2,
    thousandSeparator: '.',
    decimalSeparator: ',',
  });

  private unmask(value: string) {
    const unmasked = maskitoParseNumber(value, ',');
    if (isNaN(unmasked)) {
      return null;
    }
    return unmasked;
  }

  elementPredicate: MaskitoElementPredicate = async (element) =>
    (element as HTMLIonInputElement).getInputElement();

  ngOnChanges(changes: SimpleChanges) {
    const currentValue = changes['value'].currentValue ?? '';
    const maskedValue = maskitoTransform(
      currentValue.toString().replace('.', ','),
      this.options,
    );

    if (maskedValue !== this.formControl.value) {
      this.formControl.setValue(maskedValue);
      this.unmaskedValue = this.unmask(maskedValue);
    }
  }

  inputChanged() {
    this.unmaskedValue = this.unmask(this.formControl.value ?? '');
    this.valueChange.emit(this.unmaskedValue);
  }
}
