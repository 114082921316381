import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';

import { ButtonFormWrapperUiComponent } from './button-form-wrapper-ui/button-form-wrapper-ui.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormlyModule.forChild({
      wrappers: [
        {
          name: 'button',
          component: ButtonFormWrapperUiComponent,
        },
      ],
    }),
  ],
  declarations: [ButtonFormWrapperUiComponent],
  exports: [ButtonFormWrapperUiComponent],
})
export class SharedFormWrapperUiButtonModule {}
