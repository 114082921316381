import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';

import { strict as assert } from 'assert';

import { NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';

@Component({
  selector: 'ts-link-ui',
  templateUrl: './link-ui.component.html',
  styleUrls: ['./link-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkUiComponent implements OnChanges {
  /**
   * If given, the link will have the following as its routerLink.
   */
  // we use any since routerLink also use any, nothing we can really do
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() routerLinkParams?: [string, ...any];
  @Input() routerLinkOptions?: NavigationOptions;
  @Input() routerDirection: Parameters<NavController['setDirection']>[0] =
    'root';
  // TODO(irvan): these router links should ideally be merged into a single param. But later...

  /**
   * Url to an external site. Will be opened in a new tab.
   */
  @Input() externalUrl?: string;

  /**
   * If externalUrl and routerLinkParams are not given, then this will be used
   * as the href. It won't actually be opened when the user clicks on it.
   * You'll have to implement a (click) to make use of the event.
   */
  @Input() displayHref = '#';

  ngOnChanges() {
    assert(!this.routerLinkParams || !this.externalUrl);
    if (!this.routerLinkParams) {
      assert(!this.routerLinkOptions);
    }
  }
}
