import { Injectable } from '@angular/core';

import { FormlyFieldConfig } from '@ngx-formly/core';
import { ShippingMethodCode } from '@ts/shipping/shared/util-core';

interface ShippingMethodOption {
  text: string;
  value: {
    shipping_code: ShippingMethodCode;
  };
}

@Injectable()
export class ShippingMethodsSelectPresetProvider {
  SHIPPING_METHOD_CODES: {
    [shippingMethodCode in ShippingMethodCode]: string;
  } = {
    'biteship:grab:instant': 'Biteship: Grab Motor Instant',
    'biteship:grab:instant_car': 'Biteship: Grab Mobil Instant',
    'biteship:lalamove:mpv': 'Biteship: Lalamove Mobil',
    'biteship:lalamove:truck': 'Biteship: Lalamove Truk',
    'biteship:lalamove:van': 'Biteship: Lalamove Van',
    'point-of-sales': 'Point of Sales',
    'self-pickup': 'Ambil Sendiri',
  };

  getConfiguration(): FormlyFieldConfig {
    return {
      type: 'select',
      props: {
        label: 'Pilih metode pengiriman',
        multiple: true,
        options: [...this.getShippingMethodOptions()],
        valueProp: 'value',
        labelProp: 'text',
        required: true,
      },
    };
  }

  private getShippingMethodOptions(): readonly ShippingMethodOption[] {
    return Object.entries(this.SHIPPING_METHOD_CODES).map(([key, value]) => ({
      text: value,
      value: {
        shipping_code: key as ShippingMethodCode,
      },
    }));
  }
}
