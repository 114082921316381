<ts-pagination-header [pagingMeta]="pagingMeta"></ts-pagination-header>

<ng-content></ng-content>

<ts-pagination-control-ui
  *ngIf="pagingMeta.totalEntities"
  [pagingMeta]="pagingMeta"
  (pagingPageChanged)="moveToPagingPage($event)"
>
</ts-pagination-control-ui>
