import { Injectable } from '@angular/core';

import { GuidPrefix } from './guid.model';

export interface GuidMeta {
  url: string;
}

@Injectable({
  providedIn: 'root',
})
export class GuidMetaService {
  GUID_PREFIX_TO_GUID_META: Record<GuidPrefix, GuidMeta> = {
    CAS: { url: '/finance/cash-storage/list' },
    CLN: { url: '/finance/cash-flow/lane/list' },
    CFL: { url: '/finance/cash-flow/list' },
    CFM: { url: '/finance/cash-flow/manual/list' },
    COS: { url: '' },
    CSR: { url: '/order/offline/cashier/list' },
    CTC: { url: '' },
    FCZ: { url: '/facility-zone/list' },
    FLI: { url: '/finance/liability/fixed-liability/list' },
    IPC: { url: '/inventory/replenishment/channel/list' },
    IPL: { url: '/inventory/replenishment/list' },
    IRC: { url: '/inventory/reconciliation/list' },
    LIA: { url: '/finance/liability/list' },
    ORD: { url: '/order/list' },
    ORR: { url: '/order/return/list' },
    PLP: { url: '/printer/label/product/list' },
    PPC: { url: '/price-purchase/change/list' },
    PPR: { url: '/price-purchase/record/list' },
    PRN: { url: '/printer/list' },
    PRM: { url: '/promo/list' },
    PSC: { url: '/price-sell/change/list' },
    PUG: { url: '/purchasing/group/list' },
    PUR: { url: '/purchasing/list' },
    RCV: { url: '/receiving/list' },
    RET: { url: '/return-to-supplier/list' },
    RTS: { url: '/receiving/tally-sheet/list' },
    SPY: { url: '/finance/supplier-payment/list' },
    SUP: { url: '/supplier/list' },
    TRL: { url: '/transport/lane/list' },
    TRN: { url: '/transport/list' },
    TRM: { url: '/transport/manual/list' },
  };

  parseGuid(guid: string): { prefix: GuidPrefix; id: number } {
    /**
     * Throws TypeError when guid is not an actual guid.
     */
    const match = guid.match(/^([A-Z]{3})-(\d+)$/);

    if (!match) {
      throw new TypeError(
        `Invalid format: expected "XXX-NUMBER", but got "${guid}"`,
      );
    }

    const [, prefix, id] = match;
    return {
      prefix: prefix as GuidPrefix,
      id: parseInt(id),
    };
  }

  getGuidMeta(guid: string): GuidMeta {
    /**
     * Throws TypeError when guid is not an actual guid.
     */
    const parsedGuid = this.parseGuid(guid);
    return this.GUID_PREFIX_TO_GUID_META[parsedGuid.prefix as GuidPrefix];
  }
}
