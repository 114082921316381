<a *ngIf="externalUrl; else noExternalUrl" [href]="externalUrl" target="_blank">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>

<ng-template #noExternalUrl>
  <a
    *ngIf="routerLinkParams; else noRouterLinkParams"
    [tsRouterLink]="routerLinkParams"
    [routerLinkOptions]="routerLinkOptions"
    [routerDirection]="routerDirection"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
</ng-template>

<ng-template #noRouterLinkParams>
  <a [href]="displayHref" (click)="(false)">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
