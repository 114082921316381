import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { Observable } from 'rxjs';

import { QueryParamsService } from '@ts/shared/router/data-access-params';

@Component({
  selector: 'ts-searchbar-result-title-ui',
  templateUrl: './searchbar-result-title-ui.component.html',
  styleUrls: ['./searchbar-result-title-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchbarResultTitleUiComponent implements OnInit {
  queryParam$!: Observable<string | undefined>;

  constructor(private queryParamsService: QueryParamsService) {}

  ngOnInit() {
    this.queryParam$ = this.queryParamsService.getQueryParam$('q');
  }
}
