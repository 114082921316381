import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { IonPopover } from '@ionic/angular';

/**
 * DOES NOT LAZY LOAD!
 */
@Component({
  selector: 'ts-popover-button-content-ui',
  templateUrl: './popover-button-content-ui.component.html',
  styleUrls: ['./popover-button-content-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverButtonContentUiComponent {
  @ViewChild('popover') popover!: IonPopover;

  popoverShow(event: Event) {
    this.popover.event = event;
    this.popover.present();
  }
}
