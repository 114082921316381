import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LinkButtonConfig } from '@ts/shared/link/ui-button';

@Component({
  selector: 'ts-staff-list-wrapper-ui',
  templateUrl: './staff-list-wrapper-ui.component.html',
  styleUrls: ['./staff-list-wrapper-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaffListWrapperUiComponent {
  @Input() linkButtonConfigs!: LinkButtonConfig[];
}
