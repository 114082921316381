import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { CashierPagingService } from '@ts/order/offline/cashier/list/data-access';
import { Cashier } from '@ts/order/shared/util-core';
import { TableInfiniteConfig } from '@ts/shared/table/feature-generic';

@Component({
  selector: 'ts-cashier-select-modal',
  templateUrl: './cashier-select-modal.component.html',
  styleUrls: ['./cashier-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierSelectModalComponent {
  @Input() initial?: Cashier;

  tableConfig: TableInfiniteConfig<Cashier> = {
    type: 'infinite',
    pagingService: this.cashierPagingService,
  };

  constructor(
    private modalController: ModalController,
    private cashierPagingService: CashierPagingService,
  ) {}

  cashierSelected(cashier: Cashier | null) {
    this.modalController.dismiss({ cashier });
  }
}
