import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PrinterLabelHtmlConfig } from '@ts/printer/label/shared/util-core';

@Component({
  selector: 'ts-printer-label-html-config-mention-ui',
  templateUrl: './printer-label-html-config-mention-ui.component.html',
  styleUrls: ['./printer-label-html-config-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterLabelHtmlConfigMentionUiComponent {
  @Input() printerLabelHtmlConfig!: PrinterLabelHtmlConfig;
}
