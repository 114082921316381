import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Cashier } from '@ts/order/shared/util-core';

@Component({
  selector: 'ts-cashier-mention-ui',
  templateUrl: './cashier-mention-ui.component.html',
  styleUrls: ['./cashier-mention-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierMentionUiComponent {
  @Input() cashier!: Cashier;
}
