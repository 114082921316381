<tr *ngFor="let purchasing of purchasings">
  <td>
    <ts-guid-ui [guid]="purchasing.guid"></ts-guid-ui>
    <ts-date-created-updated-ui
      [instance]="purchasing"
    ></ts-date-created-updated-ui>
  </td>
  <td>
    <ts-supplier-mention-ui
      [supplier]="purchasing.supplier"
    ></ts-supplier-mention-ui>
  </td>
  <td><ts-money-ui [money]="purchasing.amount_total"></ts-money-ui></td>
  <td>{{ purchasing.status }}</td>
  <td>
    <ts-guid-link [guid]="purchasing.purchasing_group.guid"></ts-guid-link>
  </td>
  <td *ngIf="actionTemplate">
    <ng-template
      [ngTemplateOutlet]="actionTemplate"
      [ngTemplateOutletContext]="{ value: purchasing }"
    ></ng-template>
  </td>
</tr>
