import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { PrinterLabelZebraConfig } from '@ts/printer/label/shared/util-core';

@Component({
  selector: 'ts-printer-label-zebra-config-select-modal',
  templateUrl: './printer-label-zebra-config-select-modal.component.html',
  styleUrls: ['./printer-label-zebra-config-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterLabelZebraConfigSelectModalComponent {
  @Input() initial?: PrinterLabelZebraConfig;

  constructor(private modalController: ModalController) {}

  printerLabelZebraConfigSelected(
    printerLabelZebraConfig: PrinterLabelZebraConfig | null,
  ) {
    this.modalController.dismiss({ printerLabelZebraConfig });
  }
}
