import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PrinterLabelHtmlConfigMentionUiComponent } from './printer-label-html-config-mention-ui/printer-label-html-config-mention-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [PrinterLabelHtmlConfigMentionUiComponent],
  exports: [PrinterLabelHtmlConfigMentionUiComponent],
})
export class PrinterLabelHtmlConfigUiMentionModule {}
