import { Injectable } from '@angular/core';

import { of } from 'rxjs';

import { PrinterLabelZebraConfig } from '@ts/printer/label/shared/util-core';
import { BormaDagoPersistentAuthenticatedApiConsumerService } from '@ts/shared/api/data-access-borma-dago-authenticated-api-consumer';
import { ApiEntityStreamPersistentAbstractService } from '@ts/shared/data/data-access-api-entity-stream';

/**
 * Retrieves all zebra configs.
 *
 * Persistent.
 */
@Injectable({
  providedIn: 'root',
})
export class PrinterLabelZebraConfigsStreamService extends ApiEntityStreamPersistentAbstractService<
  readonly PrinterLabelZebraConfig[]
> {
  override relativeUrl$ = of(
    'api/printer/label/staff/zebra-configuration/all/',
  );

  constructor(
    protected override apiService: BormaDagoPersistentAuthenticatedApiConsumerService,
  ) {
    super();
  }
}
