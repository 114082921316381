import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Cashier } from '@ts/order/shared/util-core';
import { LinkButtonConfig } from '@ts/shared/link/ui-button';
import { Paging } from '@ts/shared/pagination/util-core';

@Component({
  selector: 'ts-cashier-list-ui',
  templateUrl: './cashier-list-ui.component.html',
  styleUrls: ['./cashier-list-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashierListUiComponent {
  @Input() cashierPaging!: Paging<Cashier>;

  linkButtonConfigs: LinkButtonConfig[] = [
    {
      link: '/order/offline/cashier/new',
      permission: 'order_offline.add_cashier',
      textTranslationKey: 'sharedButton.create',
      ionIconName: 'add',
    },
    {
      link: '/order/offline/cashier/revision',
      permission: 'order_offline.view_cashier',
      textTranslationKey: 'sharedButton.revision',
      color: 'medium',
    },
  ];
}
