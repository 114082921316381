import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';

import { Observable } from 'rxjs';

import { PrinterLabelHtmlConfigsStreamService } from '@ts/printer/label/data-access-config';
import { PrinterLabelHtmlConfig } from '@ts/printer/label/shared/util-core';
import { ActionTemplateContext } from '@ts/shared/choice/ui-action-template';

@Component({
  selector: 'ts-printer-label-html-config-table',
  templateUrl: './printer-label-html-config-table.component.html',
  styleUrls: ['./printer-label-html-config-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterLabelHtmlConfigTableComponent implements OnInit {
  @Input() actionTemplate!: TemplateRef<
    ActionTemplateContext<PrinterLabelHtmlConfig>
  >;

  printerLabelHtmlConfigs$!: Observable<
    readonly PrinterLabelHtmlConfig[] | null
  >;

  constructor(
    private printerLabelHtmlConfigsStreamService: PrinterLabelHtmlConfigsStreamService,
  ) {}

  ngOnInit() {
    this.printerLabelHtmlConfigs$ =
      this.printerLabelHtmlConfigsStreamService.entity$;
  }
}
