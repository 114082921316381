import { Timestamp } from '@ts/shared/date-time/util-core';
import { User } from '@ts/user/shared/util-core';

export type ModelRevisionStatus = 'new' | 'update';

export const REVISION_FILTER_OBJECT_ID_QUERY_PARAM_KEY = 'object_id';

interface RevisionWithPreviousFilterQueryParamsCommon {
  revision__date_created__gte?: Timestamp;
  revision__date_created__lte?: Timestamp;
  object_id?: number;
  model_revision_status?: ModelRevisionStatus;
}

interface RevisionWithPreviousFilterQueryParamsBase
  extends RevisionWithPreviousFilterQueryParamsCommon {
  revision__user_id?: number;
}

interface RevisionWithPreviousFilterQueryParamsFormModelBase
  extends RevisionWithPreviousFilterQueryParamsCommon {
  user?: User;
}

export type RevisionWithPreviousFilterQueryParams =
  Readonly<RevisionWithPreviousFilterQueryParamsBase>;

export type RevisionWithPreviousFilterQueryParamsFormModel =
  Readonly<RevisionWithPreviousFilterQueryParamsFormModelBase>;
