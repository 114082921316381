import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { I18nService } from '@ts/shared/18n/util-core';
import { Permission } from '@ts/shared/permission/util-core';
import { IonColor } from '@ts/shared/util-ionic';

export interface LinkButtonConfig {
  textTranslationKey: string;
  color?: IonColor;
  permission: Permission;
  link: string;
  ionIconName?: string;
}

@Component({
  selector: 'ts-link-button-ui',
  templateUrl: './link-button-ui.component.html',
  styleUrls: ['./link-button-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkButtonUiComponent implements OnInit {
  @Input() linkButtonConfig!: LinkButtonConfig;

  buttonDisplayedText!: Promise<string>;

  constructor(private i18nService: I18nService) {}

  ngOnInit() {
    this.buttonDisplayedText = this.i18nService.translate$(
      this.linkButtonConfig.textTranslationKey,
    );
  }
}
