import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NavController } from '@ionic/angular';
import { PagingMeta } from '@ts/shared/pagination/util-core';
import { ScrollService } from '@ts/shared/util-scroll';

/**
 * Encapsulates pagination, but also reroutes user to the same page when
 * they click the page navigation buttons.
 * The url will remain the same except that the "page" get parameter is swapped
 * to something else.
 *
 * @example
 *
 * ```
 * <ts-pagination [pagingMeta]="pagingMeta">
 *   <div *ngFor=...>
 *     Item xxx
 *   </div>
 * </ts-pagination>
 * ```
 */
@Component({
  selector: 'ts-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  @Input() pagingMeta!: PagingMeta;

  @ViewChild('scrollPosition') scrollPosition!: ElementRef;

  // we put this here instead of in constants in case we want to make this an @Input() later.
  pagingPageQueryParameterName = 'page';

  constructor(
    private activatedRoute: ActivatedRoute,
    private navController: NavController,
    private scrollService: ScrollService,
  ) {}

  moveToPagingPage(page: number) {
    this.navController.navigateRoot([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        [this.pagingPageQueryParameterName]: page,
      },
      queryParamsHandling: 'merge',
    });

    this.scrollService.scrollToTop();
  }
}
