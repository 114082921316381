import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { IonicModule } from '@ionic/angular';
import { FormlyModule } from '@ngx-formly/core';
import { PrinterLabelHtmlConfigUiMentionModule } from '@ts/printer/label/html-config/ui-mention';
import { SharedFormFieldUiSelectWithModalModule } from '@ts/shared/form/field/ui-select-with-modal';

import { PrinterLabelHtmlConfigFormFieldComponent } from './printer-label-html-config-form-field/printer-label-html-config-form-field.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedFormFieldUiSelectWithModalModule,
    FormlyModule.forChild({
      types: [
        {
          name: 'printer-label-html-config',
          component: PrinterLabelHtmlConfigFormFieldComponent,
          wrappers: ['form-field'],
        },
      ],
    }),
    PrinterLabelHtmlConfigUiMentionModule,
  ],
  declarations: [PrinterLabelHtmlConfigFormFieldComponent],
  exports: [PrinterLabelHtmlConfigFormFieldComponent],
})
export class PrinterLabelHtmlConfigFormFeatureFieldModule {}
