import { FormlyFieldConfig } from '@ngx-formly/core';

export const REVISION_WITH_PREVIOUS_SEARCH_QUERY_PARAMS_FILTER_FORM_FIELDS: readonly FormlyFieldConfig[] =
  [
    {
      key: 'revision__date_created__gte',
      type: 'date-time',
      props: {
        i18n: true,
        label: 'filter.dateCreatedGte',
        presentation: 'date',
      },
    },
    {
      key: 'revision__date_created__lte',
      type: 'date-time',
      props: {
        i18n: true,
        label: 'filter.dateCreatedLte',
        presentation: 'date',
      },
    },
    {
      key: 'user',
      type: 'user',
      props: {
        label: 'Pengguna',
      },
    },
    {
      key: 'model_revision_status',
      type: 'button-select',
      props: {
        label: 'Jenis Revisi',
        options: [
          { label: 'New', value: 'new' },
          { label: 'Update', value: 'update' },
        ],
      },
    },
  ];
