import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { PrinterLabelHtmlConfig } from '@ts/printer/label/shared/util-core';

@Component({
  selector: 'ts-printer-label-html-config-select-modal',
  templateUrl: './printer-label-html-config-select-modal.component.html',
  styleUrls: ['./printer-label-html-config-select-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrinterLabelHtmlConfigSelectModalComponent {
  @Input() initial?: PrinterLabelHtmlConfig;

  constructor(private modalController: ModalController) {}

  printerLabelZebraConfigSelected(
    printerLabelHtmlConfig: PrinterLabelHtmlConfig | null,
  ) {
    this.modalController.dismiss({ printerLabelHtmlConfig });
  }
}
