import { Injectable } from '@angular/core';

import _ from 'lodash';

import { HistoryDifference, HistoryWithPrevious } from './history.model';

@Injectable({
  providedIn: 'root',
})
export class HistoryDifferenceService {
  getHistoryDifferences(
    historyWithPrevious: HistoryWithPrevious,
  ): readonly HistoryDifference[] {
    const historyDifferences: HistoryDifference[] = [];

    const currentKeys = Object.keys(historyWithPrevious.version.field_dict);

    for (const key of currentKeys) {
      if (
        !_.isEqual(
          historyWithPrevious.version.field_dict[key],
          historyWithPrevious.version_previous?.field_dict[key],
        )
      ) {
        historyDifferences.push({
          key,
          now: historyWithPrevious.version.field_dict[key],
          previous: historyWithPrevious.version_previous?.field_dict[key],
        });
      }
    }

    return historyDifferences;
  }
}
